import React, { Suspense, lazy } from "react";
import PersonalSlide from "../../components/projects/PersonalSlide";
import Style from "../../styles/Projects.module.css";
import { motion } from "framer-motion";
const ProjectsSlider = lazy(() =>
  import("../../components/projects/ProjectsSlider")
);
const Projects = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={Style.container}>
        <PersonalSlide />
        <Suspense
          fallback={
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          }
        >
          <ProjectsSlider />
        </Suspense>
      </div>
    </motion.div>
  );
};

export default Projects;
