import React, { useState } from "react";
import PersonalCards from "./PersonalCards";
import data from "../../data/contents.json";
import MySlider from "../slider/MySlider";
import style from "../../styles/PersonalSlider.module.css";

const PersonalSlide = () => {
  const [current, setCurrent] = useState(0);
  return (
    <div className={style.container}>
      <h2 className={style.title}>PERSONAL</h2>
      <MySlider type={"personal"}>
        {data.map((project, i) => {
          return (
            project.type === "personal" && (
              <PersonalCards
                key={i}
                i={i}
                imgSrc={project.img[0]}
                title={project.title}
                description={project.desc[0]}
                position={project.coverPostion ? project.coverPostion : null}
                setCurrent={setCurrent}
                current={current}
              />
            )
          );
        })}
      </MySlider>
    </div>
  );
};

export default PersonalSlide;
