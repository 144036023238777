import styles from "../styles/Home.module.css";

import HomePage from "../components/home/HomePage";
import WelcomeModal from "../components/main/WelcomModal";

import { motion } from "framer-motion";
export default function Home({ welcome }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.container}>
        {welcome && <WelcomeModal />}
        <HomePage />
      </div>
    </motion.div>
  );
}
