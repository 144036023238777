import React, { useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "../../styles/PersonalCard.module.css";
import { Link } from "react-router-dom";

const PersonalCards = ({ imgSrc, title, position, i, current, setCurrent }) => {
  const cardRef = useRef();

  const callbackFunction = (entries) => {
    if (entries[0].isIntersecting) {
      let x = entries[0].target.getBoundingClientRect().x;
      let y = entries[0].target.getBoundingClientRect().y;
    }
  };

  // Intersection observer
  useEffect(() => {
    const card = cardRef.current;
    const options = {
      root: null,
      threshold: 0.7,
      rootMargin: "100% 0% 100% 0%",
    };
    const observer = new IntersectionObserver(callbackFunction, options);

    if (card) {
      observer.observe(card);
    }
  }, [cardRef]);

  return (
    <div
      data-index-number={i}
      className={styles.cardContainer}
      ref={cardRef}
      id={"personalSlide"}
      style={{
        marginLeft: `${i === 0 ? 0 : ""}`,
      }}
    >
      <Link to={`/Projects/${title}`}>
        <div className={styles.modalContainer}>
          <h1 className={styles.title}>{title}</h1>
          {/* <p className={styles.description}>{description} &#x0226B;</p> */}
        </div>
      </Link>
      <LazyLoadImage
        effect="blur"
        alt={title}
        width={"100%"}
        height={"100%"}
        className={styles.img}
        style={position ? { objectPosition: `50% ${position}` } : null}
        src={imgSrc}
      />
    </div>
  );
};

export default PersonalCards;
